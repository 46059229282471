export default {
  public: [],
  admin: [
    // 會員列表
    {
      path: 'member',
      name: 'member',
      component: () => import('@/modules/member/views/memberList.vue'),
    },

    // 會員表單
    // {
    //   path: 'member/create',
    //   name: 'member-create',
    //   meta: {
    //     formMode: 'create',
    //   },
    //   component: () => import('@/modules/member/views/memberForm.vue'),
    // },
    {
      path: 'member/update/:target',
      name: 'member-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/member/views/memberForm.vue'),
    },

  ],
}
